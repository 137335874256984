import React, { Component } from 'react';
import './App.css';
import Logo from './logo.png';

import { Button, Modal, Container, Row, Col, FormGroup, Form } from 'react-bootstrap';
import qs from 'query-string';
import setting from './setting';
import request from 'request';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import papa from 'papaparse';
import csvFile from './data.csv';
import { BsFillTerminalFill } from 'react-icons/bs';
import Footer from './footer';
import Select from 'react-select';

// const _ = require('underscore');
const axios = require('axios');

class Order extends Component {

    constructor(props) {
        let flag = 'test';
        let url = window.location.href.split('?')[1];
        if (url) {
            if (url.includes('#')) {
                url = url.replace('#', '~');
            }
            let params = qs.parse(url);
            console.log(window.location.href);
            console.log(params);

            if (params.flag) {
                flag = params.flag.replace('~', '#');
                console.log(flag);
            }
        }

        super(props);
        this.state = {
            button: '',
            datetime: new Date(),
            flag: flag,
            showHide: false,
            showHide2: false,
            showHide3: false,
            showHide4: false,
            song: '',
            artist: '',
            category: '*',
            categories: [],
            datas: [],
            rows: [],
            selectedItem: null,
            selectedRows: [],
            categorySelected: {},
            itemSelected: {},
            comment: '',
            Pays: [],
            Calls: []
        };
        this.ButtonPay = this.ButtonPay.bind(this);
        this.ButtonOrder = this.ButtonOrder.bind(this);
        this.handleModalShowHide = this.handleModalShowHide.bind(this);
        this.handleModalShowHide2 = this.handleModalShowHide2.bind(this);
        this.handleModalShowHide3 = this.handleModalShowHide3.bind(this);
        this.handleModalShowHide4 = this.handleModalShowHide4.bind(this);
        this.handleModalShowHide5 = this.handleModalShowHide4.bind(this);
        this.saveSong = this.saveSong.bind(this);
        this.onArtistChanged = this.onArtistChanged.bind(this);
        this.onSongChanged = this.onSongChanged.bind(this);
        this.onCommentChanged = this.onCommentChanged.bind(this);
        this.rowsFilter = this.rowsFilter.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.delItem = this.delItem.bind(this);
        this.sentOrder = this.sentOrder.bind(this);
        this.onGroupChange = this.onGroupChange.bind(this);
    };

    makeStyles() {
        return makeStyles({
            table: {
                minWidth: 450,
            },
        });
    }

    createNotification(type) {
        return () => {
            // eslint-disable-next-line default-case
            switch (type) {
                case 'info':
                    NotificationManager.info('Info message');
                    break;
                case 'success':
                    NotificationManager.success('รับทราบแล้วครับ นายท่าน !!!', 'เสี่ยวเอ้อ', 3000);
                    break;
                case 'warning':
                    NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
                    break;
                case 'error':
                    NotificationManager.error('Error message', 'Click me!', 5000, () => {
                        alert('callback');
                    });
                    break;
            }
        };
    };

    componentDidMount() {
        let rs = [];
        let index = 0;
        let itemIndex = 0;
        papa.parse(csvFile, {
            download: true,
            complete: (input) => {
                // console.log(input);
                let datas = [];
                let cats = [];
                let lastName = '';
                let lastCategory = '';
                input.data.forEach(data => {
                    // console.log(data);

                    let name = '';
                    let category = '';
                    if (index !== 0 && (data[2] !== '' || data[3] !== '' || data[7] !== '') && data.length > 30) {
                        if (data[2] !== '') {
                            lastName = data[2].split('(')[0].trim();
                        }
                        // console.log(lastName);

                        if (data[3] !== '') {
                            lastCategory = data[3].split('(')[0].trim();
                            if (cats.length === 0) {
                                cats.push('+++ ทั้งหมด +++');
                            }
                            if (!cats.includes(lastCategory) && lastCategory !== 'อุปกรณ์' && lastCategory !== 'ลูกค้า' && lastCategory !== 'ชุดเคียง') {
                                cats.push(lastCategory);
                                // console.log(data[3]);
                            }
                        }
                        // console.log(lastName);

                        if (data[7] !== '') {
                            name = lastName + ' ' + data[7].split('(')[0].trim();
                        } else {
                            name = lastName;
                        }

                        if (data[8] !== '') {
                            name = name + ' ' + data[8].split('(')[0].trim();
                        }

                        if (name.includes('7 ร้อยลี้')) {
                            category = "แนะนำ";
                        } else {
                            category = lastCategory;
                        }

                        // console.log(name);

                        if (category && name !== 'คน') {
                            datas.push({
                                index: itemIndex,
                                category: category,
                                name: name,
                                price: parseFloat(data[21])
                            });
                            itemIndex++;
                        }
                    }
                    // else if (index === 0) {
                    //     datas.push({
                    //         index: -1,
                    //         category: "",
                    //         name: 'เลือกรายการ',
                    //         price: 0
                    //     });
                    // }
                    index++;
                });
                let arrFirst = ['+++ ทั้งหมด +++'];
                cats = cats.sort((a, b) => {
                    if (a < b) { return -1; }
                    if (a > b) { return 1; }
                    return 0;
                });

                this.setState({
                    datas: datas,
                    categories: arrFirst.concat(cats)
                });
                if (datas.length > 0) {
                    datas.forEach(data => {
                        rs.push(data);
                    });
                    this.setState({
                        rows: rs
                    });
                    console.log(rs);
                } else {
                    console.log("Data not found !!!");
                }
            }
        });

        // var headers = {
        //     'Authorization': 'Bearer a4d76ebfb55b443bb543996356d2f5d6',
        //     'Access-Control-Allow-Origin': '*'
        // }
        // axios.get(`${setting.loyverse_api}/items`, headers)
        //     .then(res => {
        //         console.log(res.data);
        //     })
        //     .catch(function (error) {
        //         console.error(error);
        //     });
    }

    handleModalShowHide() {
        this.setState({
            showHide: !this.state.showHide
        });
    }

    handleModalShowHide2() {
        this.setState({
            showHide2: !this.state.showHide2,
            selectedItem: null,
            selectedRows: [],
            showHide4: false
        });
    }

    handleModalShowHide3() {
        this.setState({
            showHide3: !this.state.showHide3
        });
    }

    handleModalShowHide4(event) {
        this.setState({
            showHide4: !this.state.showHide4
        });
        console.log(event);
        if (this.state.showHide4 && event && event.target.innerText === "เรียกเสี่ยวเอ้อ") {
            let lastNofity = this.state.Calls.filter((p) => {
                let vr = null;
                if (p[0] === this.state.flag) {
                    vr = p[1];
                }
                return vr;
            })[0];
            let isCanNotify = true;
            if (lastNofity) {
                let dn = new Date();
                let diff = dn - lastNofity[1];
                if (diff < 90000) {
                    isCanNotify = false;
                }
            }
            if (isCanNotify) {
                let msg = `${this.state.flag} เรียกใช้เสี่ยวเอ้อ...`;
                this.notify(msg);
                axios.post('https://vapi.realtime.co.th/api/use/bampro/collection/orders', [
                    {
                        "process": "call waiter",
                        "Datetime": new Date(),
                        "flag": this.state.flag
                    }
                ])
                    .then(res => {
                        // console.log(res.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                this.updateCalls(this.state.flag, [this.state.flag, new Date()]);
            } else {
                NotificationManager.warning('นายท่านโปรดสักครู่นะเจ้าคะ เสี่ยวเอ้อรับทราบแล้ว !!!', null, 3000);
            }
        }
    }

    handleModalShowHide5() {
        this.setState({
            showHide4: !this.state.showHide4
        });
    }

    ButtonOrder() {
        let msg = `${this.state.flag} เรียกสั่งอาหารเพิ่ม`;
        this.notify(msg);

        axios.post('https://vapi.realtime.co.th/api/use/bampro/collection/orders', [
            {
                "process": "order",
                "Datetime": new Date(),
                "flag": this.state.flag
            }
        ])
            .then(res => {
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    ButtonPay() {
        let msg = `${this.state.flag} เรียกเก็บตังค์`;
        let lastPaid = this.state.Pays.filter((p) => {
            let vr = null;
            if (p[0] === this.state.flag) {
                vr = p[1];
            }
            return vr;
        })[0];
        let isCanNotify = true;
        if (lastPaid) {
            let dn = new Date();
            let diff = dn - lastPaid[1];
            if (diff < 90000) {
                isCanNotify = false;
            }
        }

        if (isCanNotify) {
            this.notify(msg);
            axios.post('https://vapi.realtime.co.th/api/use/bampro/collection/orders', [
                {
                    "process": "payment",
                    "Datetime": new Date(),
                    "flag": this.state.flag
                }
            ])
                .then(res => {
                    // console.log(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

            this.updatePays(this.state.flag, [this.state.flag, new Date()]);
        } else {
            NotificationManager.warning('เสี่ยวเอ้อกำลังเตรียมข้อมูล... นายท่านโปรดสักครู่นะเจ้าคะ!!!', null, 3000);
        }
        this.handleModalShowHide3();
    }

    updatePays(flag, itemAttributes) {
        var index = this.state.Pays.findIndex(x => x[0] === flag);
        if (index !== -1) {
            this.setState({
                Pays: [
                    ...this.state.Pays.slice(0, index),
                    Object.assign({}, this.state.Pays[index], itemAttributes),
                    ...this.state.Pays.slice(index + 1)
                ]
            });
        } else {
            this.setState({
                Pays: [itemAttributes]
            });
        }
    }

    updateCalls(flag, itemAttributes) {
        var index = this.state.Calls.findIndex(x => x[0] === flag);
        if (index !== -1) {
            this.setState({
                Calls: [
                    ...this.state.Calls.slice(0, index),
                    Object.assign({}, this.state.Calls[index], itemAttributes),
                    ...this.state.Calls.slice(index + 1)
                ]
            });
        } else {
            this.setState({
                Calls: [itemAttributes]
            });
        }
    }

    onSongChanged(event) {
        this.setState({
            song: event.target.value
        });
    }

    onCommentChanged(event) {
        this.setState({
            comment: event.target.value
        });
    }

    onArtistChanged(event) {
        this.setState({
            artist: event.target.value
        });
    }

    onGroupChange(item) {
        this.setState({ category: item.value });
        this.rowsFilter(item.value);
        console.log(`Value OnGroupChange:  Lable: ${item.label}, Value: ${item.value}`);
    }

    saveSong() {
        if (this.state.song) {
            let msg = `${this.state.flag}\nขอเพลง "${this.state.song}"`;
            if (this.state.artist) {
                msg += `\n   ของ ${this.state.artist}`;
                this.notify(msg);

                axios.post('https://vapi.realtime.co.th/api/use/bampro/collection/orders', [
                    {
                        "process": "song request",
                        "Datetime": new Date(),
                        "flag": this.state.flag,
                        "artist": this.state.artist,
                        "song": this.state.song,
                    }
                ])
                    .then(res => {
                        // console.log(res.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                this.setState({
                    artist: ""
                });
                this.handleModalShowHide();
            } else {
                NotificationManager.error('นายท่านโปรดแจ้งศิลปินด้วยเจ้าค่ะ!!!', null, 3000);
            }
        } else {
            NotificationManager.error('กรอกชื่อเพลงด้วยเจ้าคะ นายท่าน!!!', null, 3000);
        }
    }

    subtotal(items) {
        return items.map((item) => item ? item.price * item.qty : 0).reduce((sum, i) => sum + i, 0);
    }

    notify(msg, showToast = true) {
        let notifyMsg = `${msg}`;
        setting.tokens.forEach((token) => {
            request({
                method: 'POST',
                uri: setting.lineNotifyUrl,
                header: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                form: {
                    message: notifyMsg, //ข้อความที่จะส่ง
                    token: token
                }
            }, (err, httpResponse, body) => {
                if (err) {
                    console.log(err);
                } else {
                    console.log(body);
                }
            });
        });
        if (showToast) {
            NotificationManager.success('รับทราบแล้วเจ้าค่ะ เสี่ยวเอ้อกำลังเดินไปหานายท่านนะเจ้าคะ !!!', 'เสี่ยวเอ้อ...', 5000);
        }
    }

    rowsFilter(value) {
        let cate = value;
        this.setState({
            category: cate
        });
        let rs = [];
        if (cate === '+++ ทั้งหมด +++') {
            this.state.datas.forEach(data => {
                rs.push(data);
            });
        } else {
            this.state.datas.forEach(data => {
                if (data.category === cate || data.category === "" || cate === "*") {
                    rs.push(data);
                }
            });
        }
        this.setState({
            rows: rs
        });
    }

    selectItem(item) {
        let its = this.state.rows.filter((x) => {
            return x.name === item.value;
        });
        if (its && its.length === 1) {
            this.setState({
                selectedItem: {
                    name: its[0].name,
                    price: its[0].price,
                    qty: 1
                }
            });
            console.log(`Item selected !!!`);
        }
    }

    addItem(event) {

        if (this.state.selectedItem) {
            let rows = this.state.selectedRows;
            let items = this.state.selectedItem;
            items.comment = this.state.comment;
            let rs = rows.filter((r) => {
                return r.name === this.state.selectedItem.name;
            });
            if (rs && rs.length > 0) {
                rs[0].qty++;

            } else {
                // rows.push(this.state.selectedItem);
                rows.push(items);

            }
            this.setState({
                selectedRows: rows
            });
            console.log(rows);
            this.setState({
                comment: ''
            });
            this.txtComment.value = "";
        }
    }

    delItem(event) {
        let item = event.target.value;
        let rows = this.state.selectedRows;
        let rs = rows.filter((r) => {
            return r.name === item;
        });
        if (rs && rs.length > 0) {
            rs[0].qty--;
            if (rs[0].qty <= 0) {
                let rws = [];
                rows.forEach((r) => {
                    if (r.name !== item) {
                        rws.push(r);
                    }
                });
                rows = rws;
            }
        }

        this.setState({
            selectedRows: rows
        });
    }

    sentOrder(event) {
        let rows = this.state.selectedRows;
        if (rows.length > 0) {
            let msg = `${this.state.flag} สั่งรายการ `;
            rows.forEach((r) => {
                if (r.comment === '') {
                    msg += `\n  - ${r.name} x ${r.qty}`;
                } else {
                    msg += `\n  - ${r.name} [${r.comment}] x ${r.qty}`;
                }
            });
            this.notify(msg, false);
            NotificationManager.success('รับรายการแล้ว นายท่านกรุณารอสักครู่ครับ !!!', 'เสี่ยวเอ้อ...', 5000);
            this.handleModalShowHide2();
        } else {
            NotificationManager.error('เลือกรายการอาการด้วยครับ\nนายท่าน !!!', 'เสี่ยวเอ้อ', 5000);
        }
    }

    getItem(name) {
        return { label: name, value: name };
    }

    render() {
        return (
            <div className="vh-100">
                <FormGroup>
                    <p className="text-center"><img src={Logo} alt="cur" /></p>
                </FormGroup >
                <FormGroup>
                    <Modal.Title style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontSize: 40,
                    }}>{this.state.flag}</Modal.Title>
                </FormGroup >
                <FormGroup>
                    <Button className="button" color="primary" size="lg" onClick={this.handleModalShowHide4}>สั่งอาหาร</Button>
                </FormGroup>
                <FormGroup>
                    <Button className="button" color="success" size="lg" onClick={this.handleModalShowHide} >ขอเพลง</Button>
                </FormGroup>
                <FormGroup>
                    <Button className="button" color="info" size="lg" onClick={this.handleModalShowHide3}>เก็บเงิน</Button>
                </FormGroup>
                <FormGroup>
                    {(this.state.flag === "นายฟ้า") ? <Button className="button" color="info" size="lg" onClick={this.handleModalShowHide3}>เปลี่ยนชื่อโต๊ะ</Button> : null}
                </FormGroup>
                <Modal show={this.state.showHide2} centered backdrop='static'>
                    <Modal.Header>
                        <Modal.Title><BsFillTerminalFill /> รายการอาหาร</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <TableContainer component={Paper}>
                                    <Table className={this.makeStyles().table} aria-label="spanning table">
                                        <TableHead style={{ backgroundColor: '#e0e0e0' }}>
                                            <TableRow>
                                                <TableCell><b>รายการ</b></TableCell>
                                                <TableCell align="right"><b>จำนวน</b></TableCell>
                                                <TableCell align="right"><b>ราคา</b></TableCell>
                                                <TableCell align="right"><b>รวม</b></TableCell>
                                                <TableCell align="right"><b>ลบ</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.selectedRows.length > 0 && this.state.selectedRows.map((row) =>
                                                <TableRow key={row.name}>
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell align="right">{row.qty}</TableCell>
                                                    <TableCell align="right">{(row.price).toFixed(2)}</TableCell>
                                                    <TableCell align="right">{(row.price * row.qty).toFixed(2)}</TableCell>
                                                    <TableCell align="right"><Button variant="light" value={row.name} onClick={this.delItem}>-</Button></TableCell>
                                                </TableRow>
                                            )}

                                            {this.state.selectedRows.length === 0 && <TableRow><TableCell colSpan={4}>เลือกรายการด้านล่าง...</TableCell></TableRow>}
                                            <TableRow>
                                                <TableCell rowSpan={4} />
                                                <TableCell align="right" colSpan={2}><b>ยอดรวมสุทธิ</b></TableCell>
                                                <TableCell align="right">{(this.subtotal(this.state.selectedRows)).toFixed(2)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Row>
                            <Row>
                                <Col xs={1} md={12} ><p /></Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group >
                                        <Form.Label>เลือกกลุ่ม</Form.Label>
                                        <Select
                                            value={this.state.categorySelected.value}
                                            onChange={this.onGroupChange}
                                            // options={[
                                            //     { value: '*', label: 'ทั้งหมด' },
                                            //     { value: 'แนะนำ', label: 'แนะนำ' },
                                            //     { value: 'ย่างเนย แจ่ว จิ้ม', label: 'ย่างเนย แจ่ว จิ้ม' },
                                            //     { value: 'อาหาร', label: 'อาหาร' },
                                            //     { value: 'อาหารยุโรป', label: 'อาหารยุโรป' },
                                            //     { value: 'เครื่องดื่ม', label: 'เครื่องดื่ม' },
                                            //     { value: 'โปรโมชั่น', label: 'โปรโมชั่น' },
                                            //     { value: 'ชุดเคียง', label: 'ชุดเคียง' },
                                            //     { value: 'อุปกรณ์', label: 'อุปกรณ์' }
                                            // ]}
                                            options={this.state.categories.map((cat) => this.getItem(cat))}
                                            placeholder="ทั้งหมด"
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col xs={5} md={5}>
                                    <Form.Group>
                                        <Form.Label>เลือกรายการอาหาร</Form.Label>
                                        <Select
                                            // value={this.state.onSelectChange}
                                            onChange={this.selectItem}
                                            options={this.state.rows.map((row) => this.getItem(row.name))}
                                            placeholder="ทั้งหมด"
                                        />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group>
                                        <Form.Label>เลือกรายการอาหาร</Form.Label>
                                        <Select
                                            // value={this.state.onSelectChange}
                                            onChange={this.selectItem}
                                            options={this.state.rows.map((row) => this.getItem(row.name))}
                                            placeholder="ทั้งหมด"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} >
                                    <input id="txtComment" className="form-control" type="text" placeholder="หมายเหตุเพิ่มเติม" ref={(el) => (this.txtComment = el)} name="txtComment" onChange={this.onCommentChanged} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={1} md={12} ><p /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group >
                                        <Form.Label><p /></Form.Label>
                                        <Button onClick={this.addItem}>เพิ่มเข้ารายการสั่ง</Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.sentOrder}>ส่งรายการ</Button>
                        <Button variant="secondary" onClick={this.handleModalShowHide2}>ยกเลิก</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showHide} centered backdrop='static'>
                    <Modal.Header>
                        <Modal.Title><BsFillTerminalFill /> กรอกรายละเอียดเพลงที่ขอ...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={12} md={12}>
                                    <input id="txtSong" className="form-control" type="text" placeholder="ชื่อเพลง (ต้องการ)" ref="txtSong" name="txtSong" onChange={this.onSongChanged} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={1} md={12} ><p /></Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <input id="txtArtist" className="form-control" type="text" placeholder="ศิลปิน" ref="txtArtist" name="txtArtist" onChange={this.onArtistChanged} />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.saveSong}>ตกลง</Button>
                        <Button variant="secondary" onClick={this.handleModalShowHide}>ยกเลิก</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showHide3} centered backdrop='static'>
                    <Modal.Header>
                        <Modal.Title><BsFillTerminalFill /> เสี่ยวเอ้อ: นายท่านต้องการเรียกเสี่ยวเอ้อคิดตังค์ จริงหรือครับ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.ButtonPay}>จริง</Button>
                        <Button variant="secondary" onClick={this.handleModalShowHide3}>ไม่จริง</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showHide4} onHide={this.handleModalShowHide5} centered>
                    <Modal.Header>
                        <Modal.Title><BsFillTerminalFill /> นายท่านต้องการสั่งอาหาร หรือเรียกใช้เสี่ยวเอ้อครับ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleModalShowHide2}>สั่งอาหาร</Button>
                        <Button variant="secondary" onClick={this.handleModalShowHide4}>เรียกเสี่ยวเอ้อ</Button>
                        <Button variant="secondary" onClick={this.handleModalShowHide5}>ยกเลิก</Button>
                    </Modal.Footer>
                </Modal>
                <NotificationContainer />
                <Footer />
            </div >
        );
    }

}

export default Order;