import React, { Component } from 'react';
import './App.css';
import { Form } from 'reactstrap';
import Orders from './Orders';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // userIdx: []
    };
  };

  render() {

    return (
      <div className="login-form">
        <Form>
          <Orders />
        </Form>
      </div>
    );
  }
}

export default App;
