module.exports = {
    apiUrl: 'http://api.really.bet/api/use/b2k/collection/',
    dateFormat: 'dd/mmm HH:MM:ss.l',
    lineNotifyUrl: 'https://linenotify.realtime.co.th',
    loyverse_api: 'https://api.loyverse.com/v1.0/',
    loyverse_access_token: 'a4d76ebfb55b443bb543996356d2f5d6',
    tokens: [
        'z3EQtjq9OI5Xw5tCGI91RW3mtfFiFk3pc515plTYQSt', // rongtium
        // 'Sm9mG5pj6rzLcFzNi3C8gEFAhFNCf6fD5eyvXbSdYFa' // family
    ]
};